import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlContainer, FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { formViewProvider } from "@app/app/shared/provider/form-view.provider";
import { BbInputComponent } from "@app/app/shared/ui/form/bb-input/bb-input.component";
import { ErrorHandlerService, MessagingService } from "@bb-core/service";
import { tapResponse } from "@ngrx/operators";
import { TranslateModule } from "@ngx-translate/core";
import { filter, switchMap } from "rxjs";
import { ShopsRepository } from "../../../data/shops.repository";
import { ShopifyShop } from "../shopify.shop";

@Component({
    selector: "bb-shopify-connection-form",
    templateUrl: "./shopify-connection-form.component.html",
    styleUrl: "./shopify-connection-form.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [formViewProvider],
    standalone: true,
    imports: [BbInputComponent, FormsModule, TranslateModule, MatButtonModule, MatIconModule],
})
export class ShopifyConnectionFormComponent {
    private readonly shopify = inject(ShopifyShop);
    private readonly controlContainer = inject(ControlContainer);
    private readonly destroyRef = inject(DestroyRef);
    private readonly shopsRepository = inject(ShopsRepository);
    private readonly messagingService = inject(MessagingService);
    private readonly errorHandlerService = inject(ErrorHandlerService);

    readonly apiTokenFieldType = signal("password");

    onClickReAuth() {
        this.shopsRepository
            .getShopFromId(this.controlContainer.control?.get("Id")?.value)
            .pipe(
                filter(shop => shop !== null),
                switchMap(shop =>
                    this.shopify.reAuth(shop).pipe(
                        tapResponse(
                            res => {
                                this.messagingService.showSnackBar("flash.reauth_shop_successful");
                                this.controlContainer.control?.patchValue({
                                    ShopifyAccessToken: res.ShopifyAccessToken,
                                    ShopName: res.ShopName,
                                })
                            },
                            e => this.errorHandlerService.handleException(e),
                        ),
                    ),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    toggleApiTokenFieldType() {
        this.apiTokenFieldType.update(type => (type === "password" ? "text" : "password"));
    }
}
